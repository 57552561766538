import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Wrapper from '../components/layout/wrapper'
import Img from "gatsby-image"
import styles from './index.module.scss'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Welcome" />
      <Wrapper>
      <section className={styles.container}>
        <div className={styles.cols}>
          <div className={styles.textCol}>
            <h1>{data.prismicHome.data.hero_title.text}</h1>
            <p>{data.prismicHome.data.hero_subtitle.text}</p>
          </div>
          <div className={styles.imgCol}>
            <Img fluid={data.prismicHome.data.hero_image.localFile.childImageSharp.fluid} />
          </div>
        </div>
      </section>
      </Wrapper>
  </Layout>
)

export default IndexPage

export const query = graphql`
query HomeQuery {
  prismicHome {
    data {
      hero_title {
        text
      }
      hero_subtitle {
        text
      }
      hero_image {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`